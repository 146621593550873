import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Input, Bouton } from "../../Elements"
import { setUserNewPassword, setUserNewPasswordVerif } from "../../Reducer/fonctions/actions"
import axiosFunc from "../../Reducer/fonctions/axios"

const AskPassword = ({ connexion, input, isPassword, button, admin}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <div>
            <p className="text__justify text__size__1-5">{admin.resetPassword.title}</p>
            <p className="text__center text__size__2">{admin.resetPassword.writeNewPassword}</p>
            <div className='container__flex container__flex__column container__flex__center'>
                <Input placeholder={input.inputPassword} action={(event) => { dispatch(setUserNewPassword(event.target.value)) }} alertInput={isPassword} secureInput={true} />
                <Input placeholder={input.inputPasswordConfirm} action={(event) => { dispatch(setUserNewPasswordVerif(event.target.value))}} alertInput={isPassword} secureInput={true} />
            </div>
            <div className='container__flex container__flex__column container__flex__center background__white'>
                <Bouton text={button.reinitialize} action={() => axiosFunc.sendNewPassword(navigate, dispatch)} />
            </div>
        </div>
    );
}

const mapStates = (state) => ({
    connexion: state.textes.connexion,
    gestion: state.gestion,
    input: state.textes.input,
    isPassword: state.gestion.isPassword,
    admin : state.textes.admin,
    button : state.textes.app.button
})

export default connect(mapStates)(AskPassword);