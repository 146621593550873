// import store from '../State/store';

const verifications = {
    isMail: (mail) => {
        const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        return regexEmail.test(mail)
    },
    isPassword: (password) => {
        const regex = /^(?=.*[!@#$%{}?*]).{8,}$/;
        return regex.test(password)
    },
    sameContent: (text1, text2) => {
        if(text1 === text2){
            return true
        }
    }
};

export default verifications; 