import { connect } from "react-redux"
import {Link, useParams} from "react-router-dom";

const MenuHaut = ({menuTable}) => {    
    const {menu, idQuestionnaire, idUser} = useParams();
        return (

        <div className="container__menuAccueil">
            
        {menuTable && menuTable[0] ? menuTable.map((item,index)=>(
            <div key={index} className='container__menuAccueil__text' >
                <Link to={`${process.env.REACT_APP_FRONT_LINK}/${item.lien}/${idUser}/${idQuestionnaire}`} className={item.lien === menu ? "menuActif link" : "link"}>
                        {item.nom}
                </Link>
                <div className={item.lien === menu ?'separator__grey' : 'separator__grey__close'} ></div>
            </div>
        )
        ): null}

        </div>
    )
}

const states = (state) => ({
    menuTable: state.textes.titles.menu
})


export default connect(states)(MenuHaut)