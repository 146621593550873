import { connect, useDispatch } from "react-redux";
import { setDisplayModalResponse } from "../../Reducer/fonctions/actions";
import { useEffect, useState } from "react";

const ModalResponses = ({data, gestionState}) => {

    const [wait, setWait] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if(wait){
            setWait(false)
        } else {
            setTimeout(() => {
            setWait(gestionState.displayModalResponse)
        }, 1000);
        }
    },// eslint-disable-next-line 
    [gestionState.displayModalResponse])

    return (
        gestionState.displayModalResponse ?
        <div className='modal'>
            <div className='modal__background' onClick={() => {dispatch(setDisplayModalResponse(false))}}></div>
                {wait ?
                <div className='modal__responses'>
                    <div className='table__title'>
                        {data.responses.infos ? <h1 className='title__modal'>{data.responses.infos.theme} - {data.responses.participant}</h1> : null}
                    </div>
                    <div className='table__responses'>
                    {data && data.responses && data.responses.answers && data.responses.answers[0] && data.responses.answers.map((d, dIndex) => {
                        return (
                            d.isTitle ? 
                            <div className='container__flex container__flex__column'>
                                <div className='table__row__title'>{d.question}</div>
                                <div className="separator" />
                            </div>
                            : <div key={dIndex} className={d.response.length > 20 ? 'table__row' : 'table__row grid__51'}>
                                <div className='table__cell'>
                                    {d.question}
                                </div>
                                <div className='table__cell__response'>
                                    {d.response}
                                </div>
                            </div>
                            
                            )
                    })}
                    </div>
                </div>
                : null}
            
        </div>
        : null
    )
}
const mapState = (state) => ({
    gestionState: state.gestion,
    data: state.data,
     });
  
  
  
  export default connect(mapState)(ModalResponses); 