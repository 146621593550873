import { combineReducers } from 'redux';
import rootTextes from './textes';
import rootGestion from './gestion';
import rootDatatable from './datatable';
import rootData from './data';

const combinedReducer = combineReducers({
  textes: rootTextes,
  gestion: rootGestion,
  dataTable: rootDatatable,
  data: rootData,

});

export default combinedReducer;
