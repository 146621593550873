const Bouton = ({text, action}) => {
    return (
        <button 
            className='button'
            onClick={action ? action : null}
        >
            {text}
        </button>
    )
}

export default Bouton