import store from '../State/store';
import { setDataThemeToExport, setDataToExport } from './actions';

const exports = {

    detailFiche : (data, dispatch, themesToExport) => {
        const state = store.getState()
        let themes = []
        let newDetailsData = []
        let newThemeData = []
        let nameTheme = ''
        if(!themesToExport){
            themes = state.data.themes
            nameTheme = 'name'
        } else {
            themes = themesToExport
            nameTheme = 'theme'
        }
        themes && themes[0] && themes.map(theme => {
            let actualThemeData = []
            data.map(user => {
                let userScore = 0
                let titleScore = 'Score du label'
                const userThemeScoreObj = user.scoreTheme.find(obj => obj.idTheme === theme.id)
                if(state.gestion.includeLabelScore){
                    titleScore = "Score global total"
                    if(userThemeScoreObj)userScore = userThemeScoreObj.userScore
                } else {
                    if(userThemeScoreObj)userScore = userThemeScoreObj.labelUserScore
                }
                if(!themesToExport){
                    newDetailsData.push({"Thème" : theme[nameTheme], "Participant" : user.participant, [titleScore] : userScore, "Score attendu" : theme.scoreAdmin })
                } else {
                    actualThemeData.push({"Participant" : user.participant, [titleScore] : userScore, "Score attendu" : theme.adminScore })
                }
                
            })
            newThemeData.push(actualThemeData)
        });
        dispatch(setDataToExport(newDetailsData))
        if(themesToExport){
            dispatch(setDataThemeToExport(newThemeData))
        } else {
            dispatch(setDataThemeToExport([]))
        }
        
    },
}

export default exports;