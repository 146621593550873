import { connect, useDispatch } from "react-redux"
import { Bar } from 'react-chartjs-2'
import { CSVLink } from "react-csv";
import {
    Chart as ChartJS,
    LinearScale,
    RadialLinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Filler,
    Legend,
    Tooltip,
    LineController,
    BarController,
  } from 'chart.js';
import { useEffect, useRef, useState } from "react";
import { Radar } from 'react-chartjs-2';
import gestion from "../../Reducer/fonctions/gestion";
import {downloadExcel } from "react-export-table-to-excel";
import { Triangle } from 'react-loader-spinner'
import ExportExcel from "../../Elements/exportExcel";


const Graphique = ({themes,participantsSelected,majorColor, includeLabel}) => {

    ChartJS.register(
        RadialLinearScale,
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Filler,
        Legend,
        Tooltip,
        LineController,
        BarController
      );

    const [selectedType, setSelectedType] = useState('line')
    const [selectedTheme, setSelectedTheme] = useState()
    const dispatch = useDispatch()
    const graphRef = useRef(null)
    const [exportedData, setExportedData] = useState([])
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([])
    
    const exportData = () => {
        setExportedData([])
        let newData = []
        if(graphRef && graphRef.current && graphRef.current.config && graphRef.current.config._config && graphRef.current.config._config.data && graphRef.current.config._config.data.labels) {
            graphRef.current.config._config.data.labels.forEach((theme, indexTheme) => {
                graphRef.current.config._config.data.datasets.forEach((user) => {
                    newData.push({"Theme" : theme, "Participant" : user.label, "Score" : user.data[indexTheme]})
                })
            })
        }

        setExportedData(newData) 
    }

    useEffect(() => {
        if(graphRef.current && graphRef.current.config){
            exportData()
        }
    }, [data])

   useEffect(() => {
        if(themes && themes[0]){
            const existingThemes = new Set(labels);
            themes.forEach(theme => {
                if (!existingThemes.has(theme.name)) {
                    setLabels(prevState => [...prevState, theme.name]);
                    existingThemes.add(theme.name);
                }
            });
        }
    }, [themes]);

      useEffect(() => {
        setData({})
        let action = null
        setTimeout(() => {
        const fetchData = async () => {
            if (selectedTheme) {
                const idTheme = themes.find((obj) => obj.name === selectedTheme).id;
                action = gestion.displayData(selectedType, true, idTheme, dispatch)
            } else {
                action = gestion.displayData(selectedType, false, null, dispatch)
            }
            try {
                const data = await action;
                setData(data);
              } catch (error) {
                console.error(error);
              }
            }     
                fetchData()
            }, 5);  

            
    }, [labels, selectedTheme, selectedType, participantsSelected, includeLabel])

    return (
        <div className='graphique'>
            <div className='graphique__legend'>
                <select onChange={(e) => setSelectedType(e.target.value)} className='graphique__select'>
                    <option value='line' className='graphique__option' >Barres verticales</option>
                    <option value='radar' className='graphique__option' >Radar</option>
                </select>
                <select onChange={(e) => setSelectedTheme(e.target.value)} className='graphique__select'>
                    <option value={''} className='graphique__option' >Choisissez un thème</option>
                    {themes.map((t, tIndex) => (
                        <option value={t.name} className='graphique__option' key={tIndex}>{t.name}</option>
                    ))}
                </select>
                <div className='graphique__toggle__container'>
                    <CSVLink data={exportedData} filename="AutoVision - Export CSV" onClick={exportData} className='graphique__toggle'>CSV</CSVLink>
                    <ExportExcel excelData={exportedData} fileName={"AutoVision - Export Excel"} sheetName={selectedTheme && selectedTheme !== 'all' ? selectedTheme : 'Global'} />
                </div>
            </div>
            <div className='graphique__display'>
                {data && data.datasets ? 
                <>
                { selectedType === 'radar' ?
                    <Radar data={data} className='graphique__open' ref={graphRef} />
                    : <Bar data={data} className='graphique__open' ref={graphRef} /> 
                }
                </>
                :
                <Triangle
                color={majorColor}
                height="25vh"
                width="25vw"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass="loader-triangle"
            />}
            </div>
        </div>
    )
}

const mapState = (state) => ({
    participantsSelected : state.dataTable.participantsSelected,
    themes: state.data.themes,
    datasets: state.dataTable.datasets,
    toggle : state.gestion.toggle,
    dataTable: state.dataTable,
    answersData: state.data.questions,
    majorColor: state.gestion.color.majorColor,
    includeLabel : state.gestion.includeLabelScore,
}) 

export default connect(mapState)(Graphique)