import { connect, useDispatch } from "react-redux"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.css';
import gestion from "../../Reducer/fonctions/gestion";
import { useEffect, useState, useRef} from "react";
import ExportExcel from "../../Elements/exportExcel";
import { CSVLink } from "react-csv";
import exports from "../../Reducer/fonctions/exports";


const Fiche = ( {dataTable, includeLabel, participantsSelected, dataToExport, dataThemeToExport}) => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [datatable, setDatatable ]= useState([])
    const dispatch = useDispatch()
    const dt = useRef(null);
    const sdt = useRef(null);


    useEffect(() => {
        const allRowIds = dataTable.participantsDetails.map((row) => row.id);
        setExpandedRows(allRowIds);
      }, [dataTable.participantsDetails])

        const rowExpansionTemplate = (rowData) => {
         const findIndexOfTheme = expandedRows.findIndex(obj => obj.id === rowData.id)
        return (
          <div className="container__underDataTable">
            <div className='container__underDataTable__title'>
              <div></div>
              <h1 className="title__datatable reset">{rowData.theme}</h1>
              <div className='container__datatable__icones'>
                <CSVLink data={dataThemeToExport && dataThemeToExport[findIndexOfTheme] ? dataThemeToExport[findIndexOfTheme] : [[]] } filename="AutoVision - Export CSV" className='graphique__toggle'>CSV</CSVLink>
                <ExportExcel excelData={dataThemeToExport && dataThemeToExport[findIndexOfTheme] ? dataThemeToExport[findIndexOfTheme] : [[]] } fileName={"AutoVision - Export Excel"} sheetName={'Fiche'} />
              </div>

            </div>
            <DataTable 
            value={gestion.calculsDataFiche(true, rowData) ? gestion.calculsDataFiche(true, rowData) : null} 
            selectionMode={'single'} ref={sdt} 
            >
              <Column field={"participant"} header="Participant" sortable></Column>
              <Column field={`userScore`} header="Score" sortable></Column>
              <Column field={`adminScore`} header="Score attendu" sortable></Column>
            </DataTable>
          </div>
        );
      };

      const fixeNumber = (product) => {
        return product.moyenne.toFixed();
    };

    useEffect(() => {
      setDatatable(gestion.calculsDataFiche(false) ? gestion.calculsDataFiche(false) : null)
      let data = []
      if(dataTable.participantsSelected && dataTable.participantsSelected[0]){
        data = dataTable.participantsSelected
      } else {
        data = dataTable.data
      }
      if(data && data[0]){
        exports.detailFiche(data, dispatch)
      }
      if(expandedRows && expandedRows[0]){
        exports.detailFiche(data, dispatch, expandedRows)
      }
    }, [includeLabel, participantsSelected, expandedRows, dataTable.data])


    return (
      dataTable.data && dataTable.data[0] ?
        <div className='page__datatable'> 
          <div className='container__datatable__icones'>
              <CSVLink data={dataToExport} filename="AutoVision - Export CSV" className='graphique__toggle'>CSV</CSVLink>
              <ExportExcel excelData={dataToExport} fileName={"AutoVision - Export Excel"} sheetName={'Fiche'} />
          </div>
            <DataTable 
                value={datatable ? datatable : null} 
                removableSort 
                scrollable
                scrollHeight="80vh"
                className='container__fiche'
                expandedRows={expandedRows}
                onRowToggle={(e) => {setExpandedRows(e.data)}}
                rowExpansionTemplate={rowExpansionTemplate}
                ref={dt}
            >
            <Column expander={true} style={{width: '5rem'}} />
            {dataTable.headersFiche.map((col, i) => {
                return(
                    col.sortable ? 
                    col.field === 'moyenne' ? 
                    <Column key={col.field} sortable field={col.field} header={col.header}  body={fixeNumber} />
                    :   <Column key={col.field} sortable field={col.field} header={col.header} />
            :  <Column key={col.field} field={col.field} header={col.header} />
            )
        })}

        </DataTable>            
        </div>
      : <div className='container__flex'> </div>
    )
}

const states = (state) => ({
    dataTable: state.dataTable,
    data: state.data,
    toggle: state.gestion.toggle,
    includeLabel : state.gestion.includeLabelScore,
    participantsSelected : state.dataTable.participantsSelected,
    dataToExport: state.gestion.dataToExport,
    dataThemeToExport : state.gestion.dataThemeToExport
})

const actions = {

}

export default connect(states, actions)(Fiche)