// ****************************************** USER ****************************************** //
export const setUser = (payload) =>                         ({type: 'SET_USER', payload})
export const setUserId = (payload) =>                       ({type: 'SET_USER_ID', payload})
export const setUserMail = (payload) =>                     ({type: 'SET_USER_MAIL', payload})
export const setUserPassword = (payload) =>                 ({type: 'SET_USER_PASSWORD', payload})
export const setUserNewPassword = (payload) =>              ({type: 'SET_USER_NEW_PASSWORD', payload})
export const setUserNewPasswordVerif = (payload) =>         ({type: 'SET_USER_NEW_PASSWORD_VERIF', payload})

// ****************************************** MENU ****************************************** //
export const setOpenMenuRight = (payload) =>                ({type: 'SET_OPEN_MENU_RIGHT', payload})

// ****************************************** DATA ****************************************** //
export const setParticipantsSelected = (payload) =>         ({type: 'SET_PARTICIPANTS_SELECTED', payload})
export const setParticipantsDetails = (payload) =>          ({type: 'SET_PARTICIPANTS_DETAILS', payload})
export const setNewDataFiche = (payload) =>                 ({type: 'SET_NEW_DATA_FICHE', payload})
export const setResponses = (payload) =>                    ({type: 'SET_RESPONSES', payload})
export const setData = (payload) =>                         ({type: 'SET_DATA', payload})
export const setChapters = (payload) =>                     ({type: 'SET_CHAPTERS', payload})
export const setQuestions = (payload) =>                    ({type: 'SET_QUESTIONS', payload})
export const setParticipants = (payload) =>                 ({type: 'SET_PARTICIPANTS', payload})

// ****************************************** TABL ****************************************** //

export const setDatasets = (payload) =>                     ({type: 'SET_DATASETS', payload})

// ****************************************** GEST ****************************************** //
export const setInputMail = (payload) =>                    ({type: 'SET_INPUT_MAIL', payload})
export const setIsMail = (payload) =>                       ({type: 'SET_IS_MAIL', payload})
export const setIsPassword = (payload) =>                   ({type: 'SET_IS_PASSWORD', payload})
export const setLoading = (payload) =>                      ({type: 'SET_LOADING', payload})
export const setPin = (payload) =>                          ({type: 'SET_PIN', payload});
export const setDisplayModalResponse = (payload) =>         ({type: 'SET_DISPLAY_MODAL_RESPONSE', payload})
export const setToggle = (payload, key) =>                  ({type: `SET_TOGGLE_${key.toUpperCase()}`, payload, key: key.toUpperCase()})
export const setMenuHaut =(payload) =>                      ({type: `SET_MENU_HAUT`,payload})
export const setAllThemes = (payload) =>                    ({type: 'SET_ALL_THEMES', payload})
export const setIncludeLabelScore = (payload) =>            ({type: 'SET_INCLUDE_LABEL_SCORE', payload})
export const setDataToExport = (payload) =>                 ({type: 'SET_DATA_TO_EXPORT', payload})
export const setDataThemeToExport = (payload) =>            ({type: 'SET_DATA_THEME_TO_EXPORT', payload})

// ****************************************** TEXT ****************************************** //
export const setAllText = (payload) =>                      ({type: 'SET_ALL_TEXT', payload})
export const setPreciseText = (payload, key) =>             ({type: `SET_${key.toUpperCase()}`, payload, key: key.toUpperCase()})