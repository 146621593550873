import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Input, Bouton } from "../../Elements"
import { setUserMail } from "../../Reducer/fonctions/actions"
import axiosFunc from "../../Reducer/fonctions/axios"

const AskMail = ({ connexion, input, isMail, user, app, textes, admin}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    console.log(textes)
    return (
        <div>
            <p className="text__justify text__size__1-5">{admin.resetPassword.title}</p>
            <p className="text__center text__size__2">{app.homePage.login}</p>
            <div className='container__flex container__flex__column container__flex__center'>
                <Input placeholder={input.inputMail} action={(event) => { dispatch(setUserMail(event.target.value))}} alertInput={isMail} defaultValue={user && user.mail ? user.mail : null} id='input__mail' />
            </div>
            <div className='container__flex container__flex__column container__flex__center background__white'>
                <Bouton text={app.button.validate} action={() => axiosFunc.verifMail(navigate, dispatch)} />
            </div>
        </div>
    );
}

const states = (state) => ({
    user: state.gestion.user,
    connexion: state.textes.connexion,
    input: state.textes.input,
    isMail: state.gestion.isMail,
    isPassword: state.gestion.isPassword,
    app: state.textes.app,
    admin: state.textes.admin,
    textes: state.textes
})

const actions = {
}

export default connect(states, actions)(AskMail);