import { connect, useDispatch } from 'react-redux'
import { setPin } from '../Reducer/fonctions/actions'

const ClavierPIN = ( {codePin} ) => {

    const table = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    const dispatch = useDispatch()

    return (
        <div>
            <div className='container__pin'>
                {table.map((pin, pinIndex) => {
                    return (
                    <div className='pin' key={pinIndex} onClick={codePin.length !== 4 ? () => {dispatch(setPin([...codePin, pin]))}: null}>
                        <p>{pin}</p>
                    </div>
                    )
                })}  
            </div>
            <div className="container__pin__message">
                <p className='pin__password'>{codePin[0] || codePin[0] === 0 ? '*' : ''}</p>
                <p className='pin__password'>{codePin[1] || codePin[1] === 0 ? '*' : ''}</p>
                <p className='pin__password'>{codePin[2] || codePin[2] === 0 ? '*' : ''}</p>
                <p className='pin__password'>{codePin[3] || codePin[3] === 0 ? '*' : ''}</p>
            </div>
        
        </div>
    )
}

const states = (state) => ({
    codePin: state.gestion.pin,
})

const actions = {
}

export default connect(states, actions)(ClavierPIN)