import Connexion from "./Pages/Connexion/connexion";
import Accueil from "./Pages/Accueil/accueil";

const routes = {
    web: [
        // PAGE DE CONNEXION
        { path: `${process.env.REACT_APP_FRONT_LINK}/connect/:step?`, component: Connexion},

        // PAGE DE DETAILS
        { path: `${process.env.REACT_APP_FRONT_LINK}/:menu/:idUser/:idQuestionnaire?/*`, component: Accueil},
    ]

}

export default routes