import { connect, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Bouton, ClavierPin } from "../../Elements"
import { setUserMail, setUserPassword } from "../../Reducer/fonctions/actions"
import axiosFunc from "../../Reducer/fonctions/axios"

const AskPin = ({ connexion, app }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <div>
            <p className="text__justify text__size__1-5">{connexion.messagePIN}</p>
            <ClavierPin />
            <div className='container__flex container__flex__column container__flex__center background__white'>
                <Bouton text={app.button.validate} action={() => axiosFunc.verifPin(dispatch, navigate)} />
            </div>
        </div>
    );
}

const states = (state) => ({
    user: state.gestion.user,
    connexion: state.textes.connexion,
    input: state.textes.input,
    isMail: state.gestion.isMail,
    isPassword: state.gestion.isPassword,
    app : state.textes.app,
})

const actions = {
    setUserMail,
    setUserPassword,
}

export default connect(states, actions)(AskPin);