const initialState = {
  validate: 'Valider',
  input: {},
  connexion: {},

  newPassword: {
    erreurMessage : 'Attention, votre mot de passe doit répondre à plusieurs critères :',
    caract: ['Au moins 8 caractères', '1 majuscule', '1 minuscule', '1 caractère spécial parmis : ! @ # $ % [ ] { } ? *' ]
  },
  titles: {
  }

  }
  
  function textes(state = initialState, action){
  
    switch (action.type){
      case 'SET_INPUT_MAIL': return{...state, input : {...state.input, inputMail: action.payload}};
      case 'SET_INPUT_MDP': return{...state, input : {...state.input, inputMdp: action.payload}};
      case 'SET_CONNEXION_INTRODUCTION': return{...state, connexion : {...state.connexion, introduction: action.payload}};
      case 'SET_CONNEXION_INDICATION': return{...state, connexion : {...state.connexion, indication: action.payload}};
      case 'SET_CONNEXION_BOUTON': return{...state, connexion : {...state.connexion, bouton: action.payload}};
      case 'SET_ALL_TEXT': return action.payload;
      case `SET_${action.key}`: return{...state, [action.key.toLowerCase()]: action.payload}
      
      default: return state;
  }
  }
  
  export default textes;