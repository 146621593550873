import { isBrowser } from 'react-device-detect';
import axiosFunc from '../Reducer/fonctions/axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Input = ( {placeholder, defaultValue, action, alertInput, secureInput, id, autoComplete} ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <input 
            type={secureInput ? 'password' : 'text'} 
            className={isBrowser ? !alertInput ? 'input' : 'input input__alert' : !alertInput ? 'input' : 'input input__alert'} 
            placeholder={placeholder ? placeholder : null} 
            defaultValue={defaultValue ? defaultValue : null} 
            onChange={action ? action : null}
            id={id}
            name={id}
            autoComplete={autoComplete}
            onKeyDown={(event) => {if (autoComplete === 'current-password' && (event.key === 'Enter' || event.keyCode === 13)) {
                axiosFunc.login(dispatch, navigate)
            }}}
        />
    )
}

export default Input