const initialState = {
    classicList: [
            {field: 'participant', header: 'Participant', sortable: true},
            {field: 'details', header: '',sortable: false},
            {field: 'finish', header: '', sortable: true},
        ],
    detailListScores: [
        {field: 'participant', header: 'Participant', sortable: true},
        {field: 'totalUserScore', header: 'Score', sortable: true},
        {field: 'totalAdminScore', header: 'Attentu', sortable: true},
        {field: 'details', header: '', sortable: false},
        {field: 'finish', header: '', sortable: true},
    ],

    detailListDates: [
        {field: 'participant', header: 'Participant', sortable: true},
        {field: 'userCreation', header: 'Création', sortable: true},
        {field: 'userModif', header: 'Modification', sortable: true},
        {field: 'userValidate', header: 'Validation', sortable: true},
        {field: 'details', header: '', sortable: false},
        {field: 'finish', header: '', sortable: true},
    ],
    
    data: [], 
    
    headersFiche: [
        {field: 'theme', header: 'Thème', sortable: true},
        {field: 'repondants', header: 'Répondants', sortable: true},
        {field: 'label', header: 'Labelisés', sortable: true},
        {field: 'moyenne', header: 'Moyenne', sortable: true},
        {field: 'adminScore', header: 'Attendu', sortable: true},
    ],
    participantsSelected: [],
    participantsDetails: [],
    datasets : [],
    }
    
    function datatable(state = initialState, action){
      switch (action.type){
        case 'SET_PARTICIPANTS_SELECTED': return{...state, participantsSelected : action.payload};
        case 'SET_NEW_DATA_FICHE': return{...state, newDataFiche: action.payload}
        case 'SET_PARTICIPANTS_DETAILS': return{...state, participantsDetails: action.payload}
        case 'SET_DATA': return{...state, data: action.payload}
        case 'SET_DATASETS': return{...state, datasets : action.payload}
        default: return state;
    }
    }
    
    export default datatable;