// == Import : npm
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './Reducer/State/store';
import { BrowserRouter as Router } from 'react-router-dom';
// == Import : local
// Composants
import App from './App/App';



// == Render
// 1. Élément React racine (celui qui contient l'ensemble de l'app)
//    => crée une structure d'objets imbriqués (DOM virtuel)
const rootReactElement = (
  <Provider store={store}>
      <Router>
        <App />
      </Router>
  </Provider>
  );
// 2. La cible du DOM (là où la structure doit prendre vie dans le DOM)
const target = document.getElementById('root');
const root = createRoot(target)
// 3. Déclenchement du rendu de React (virtuel) => DOM (page web)
root.render(rootReactElement);