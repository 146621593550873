const initialState = {
    responses: [],
    themes: {},
    chapters: {},
    questions: {},
    participants : {},
}
    
    function data(state = initialState, action){
      switch (action.type){
        case 'SET_RESPONSES': return{...state, responses : action.payload};
        case 'SET_ALL_THEMES': return{...state, themes : action.payload};
        case 'SET_CHAPTERS': return{...state, chapters : action.payload};
        case 'SET_QUESTIONS': return{...state, questions : action.payload};
        case 'SET_PARTICIPANTS': return{...state, participants : action.payload};
    
        default: return state;
    }
    }
    
    export default data;