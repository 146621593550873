import logoUranie from '../../Images/Logos/logo_uranie_refresh.png'
import { connect } from 'react-redux'
import { setUserMail, setUserPassword } from '../../Reducer/fonctions/actions'
import {useParams } from 'react-router-dom'
import Login from './login'
import AskMail from './askMail'
import AskPin from './askPin'
import AskPassword from './askPassword'

const Connexion = ( {user}) => {
    const { step } = useParams()
   
    return (
        <div className="container page__connexion">
          <img src={logoUranie} className='img__connexion' alt="Logo d'entreprise" />
          <div>
            {(() => {
                switch (step) {
                // Mail et envoie le PIN de vérification              
                case 'ask_mail': return (<AskMail />); 
                // Code PIN
                case 'ask_pin': return (<AskPin />);
                // Nouveau Mot de passe
                case 'ask_new_password': return (<AskPassword />);
                // Page par défaut
                default: return (<Login />);
                }
            })()}
          </div>
        </div>
      );
      
}

const states = (state) => ({
    connexion: state.textes.connexion,
    user: state.gestion.user,
    placeholderMail: state.textes.input.inputMail,
    placeholderMdp: state.textes.input.inputMdp,
    isMail: state.gestion.isMail,
    isPassword: state.gestion.isPassword,
})

const actions = {
    setUserMail, setUserPassword, 
}

export default connect(states, actions)(Connexion)