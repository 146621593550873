import { Link } from 'react-router-dom'
import store from '../State/store';
import Cookies from 'js-cookie';
import axiosFunc from './axios';
import {NotificationManager} from 'react-notifications';


const gestion = {

  // Sélectionne le CSS à afficher (Navigateur ou Mobile)
  importCSS: (isBrowser) => {
    if (isBrowser) {import('../../App/CSS_web')}
        else { import('../../App/CSS_mobile')}
  },

  // Ajoute l'icone 'Loupe' avec le lien à suivre
  addIco: (item, ico) => {
    return (<Link to={`https://labelexco.com/rapport/1/${item.id}`} target='u_blanck'><img src={ico} alt='loupe' className='ico' /></Link>)
  },
  // Ajoute un cercle de couleur pour l'état de l'utilisateur (orange = pas fini, vert = fini)
  addCircleState: (data) => {
    if(data.finish){ return (<div className='formes__circle formes__couleur__green ico' title='Validé' />)
    } else { return (<div className='formes__circle formes__couleur__darkOrange ico' title='En cours' />)}},


  // Calcul des différents affichages du tableau central
  calculsDataFiche: (details, rowData) => {
    const state = store.getState()
    let newDataFiche = []
    let data = []
    state.gestion.allThemes.forEach((theme) => {
      newDataFiche.push({id: theme.id, theme: theme.name, repondants: 0, label: 0, moyenne: 0, user: [], adminScore: 0})
    })
    if(state.dataTable.participantsSelected && state.dataTable.participantsSelected[0]){
      data = state.dataTable.participantsSelected
    } else {
      data = state.dataTable.data
    }
    
    data.forEach(participant => {
      participant.scoreTheme.forEach(theme => {
        const themeIndex = newDataFiche.findIndex(existingTheme => existingTheme.id === theme.idTheme);
        const themeAdminScore = state && state.data && state.data.themes && state.data.themes[0] && state.data.themes.find(obj => obj.id === theme.idTheme).scoreAdmin;
        if (themeIndex !== -1) {
          // Le thème existe déjà, mettez à jour les propriétés
          newDataFiche[themeIndex].repondants += 1;
          newDataFiche[themeIndex].label += theme.userScore > themeAdminScore ? 1 : 0;
          newDataFiche[themeIndex].moyenne += state.gestion.includeLabelScore ? theme.userScore : theme.labelUserScore;
          newDataFiche[themeIndex].user.push({infoTheme:theme, id: participant.id, participant: participant.participant, userScore: state.gestion.includeLabelScore ? theme.userScore : theme.labelUserScore, adminScore: themeAdminScore})
          newDataFiche[themeIndex].adminScore = themeAdminScore ? themeAdminScore : null;
        }});});
      // Calculer la moyenne pour chaque thème
      newDataFiche.forEach(theme => {
        if (theme.repondants > 0) {theme.moyenne /= theme.repondants;}
      });

      if(details && rowData){
        const indexMenu = newDataFiche.findIndex(obj => obj.theme === rowData.theme)
        return newDataFiche[indexMenu].user
      } else {
        return newDataFiche;
      }
  },
  findParticipantScore: (data) => {
    const state = store.getState();
    if (state.dataTable.participantsSelected && state.dataTable.participantsSelected[0]) {
      const participant = state.dataTable.participantsSelected.find((participant) => {
        return participant.scoreTheme.some((theme) => theme.theme.name === data.theme);
      });
      if (participant) {
        const objTheme = participant.theme.findIndex((theme) => theme.theme === data.theme);
        return objTheme;
      }
    }
    return -1;
  },
  disconnect: (dispatch, navigate) => {
    Cookies.remove('userToken')
    gestion.createNotification('info', 'A bientôt sur notre site', 'Vous êtes déconnecté', 4000)
    navigate(`${process.env.REACT_APP_FRONT_LINK}/connect`)
    navigate(0)
    
  },
  isConnected : () => {
    if(Cookies.userToken){
      axiosFunc.verifToken()
    }
  },
  exportCSV : (selectionOnly, data) => {
    data.current.exportCSV({ selectionOnly });
  },

/*
Fonction pour gérer les notifications
*/

createNotification:(type, message, title, time) => {
    switch (type) {
      case 'info':      NotificationManager.info(message, title ? title : null, time);break;
      case 'success':   NotificationManager.success(message, title ? title : null, time);break;        
      case 'warning':   NotificationManager.warning(message, title ? title : null, time);break;        
      case 'error':     NotificationManager.error(message, title ? title : null, time, () => {alert('callback')});break;
      default:          NotificationManager.info(message, title ? title : null, time);break;
    }
},

/*
Fonction qui modifie le texte du lien récupéré afin d'enlever les caractères spéciaux et les majuscules/
*/
modifierTexteLien:(texte) => {

    const texteModifie = texte
      .toLowerCase()
      .replace(/é|è|ê/g, 'e')
      .replace(/à/g, 'a')
      .replace(/ç/g, 'c')
      .replace(/ù|ü/g,'u');

  return texteModifie.replace(/[^a-z0-9]/g, '');
  
},

/*
* Création de l'objet de data quand l'utilisateur regarde les résultats par theme ou par chapitre
*/
displayData : async ( selectedType, isTheme, idTheme, dispatch ) => {
  const { data, dataTable, gestion } = store.getState()
  let adminData = []
  let users = []
  let dataForGraph = {datasets : [], labels : []}
  
  if(dataTable.participantsSelected && dataTable.participantsSelected[0]) {
    users = dataTable.participantsSelected
  } else {
    users = dataTable.data
  }
  try {
    if(isTheme) {
      let i = 1;
      const response = await axiosFunc.getAnswers(idTheme, null, dispatch);
      // J'ajoute les chapitres en label
      response.forEach(r => {
        if(gestion.includeLabelScore){
          dataForGraph['labels'].push(r.theme); adminData.push(r.scoreAdmin)
        } else {
          if(r.scoreAdmin > 0){
            dataForGraph['labels'].push(r.theme); adminData.push(r.scoreAdmin)
          }
        }
      })

      users.forEach((u, uIndex) => {
        let userScore = []
        response.forEach((r, rIndex) => {
          let responseScore = 0
          
          r.questions.forEach((q, qIndex) => {
            const score = q.answers.find(obj => obj.id === u.id)
            responseScore += score && score.score ? score.score : 0
          })
          if(gestion.includeLabelScore){
            userScore.push(responseScore)
          } else {
            if(r.scoreAdmin > 0){
              userScore.push(responseScore)
            }
          }
        })

        if(gestion.color.graph[i+1]){
          i++
        } else {
            i = 1
        }
        
        dataForGraph['datasets'].push({ 
          label: u.participant, 
          data: userScore, 
          borderColor: `rgba(${gestion.color.graph[i]}, 1)`, 
          backgroundColor: selectedType !== 'radar' ? `rgba(${gestion.color.graph[i]}, 0.5)` : `rgba(${gestion.color.graph[i]}, 0)`  });
      })
    } else {
      let i = 1;
      data.themes.forEach((theme) => {
        adminData.push(theme.scoreAdmin)
        dataForGraph['labels'].push(theme.name)
      })
    users.map(user => { 
      let userScore = []
      data.themes && data.themes.map(theme => {
            if(user.scoreTheme && user.scoreTheme[0]){
            const scoreObj = user.scoreTheme.find(obj => obj.idTheme === theme.id)
            if(scoreObj){
              if(gestion.includeLabelScore){
                userScore.push(scoreObj.userScore)
              } else {
                userScore.push(scoreObj.labelUserScore)
              }
            } else {
              userScore.push(0)
            }
          } else {
            userScore.push(0)
          }
         });
        if(gestion.color.graph[i+1]){
            i++
        } else {
            i = 1
        }
        dataForGraph['datasets'].push({ 
          label: user.participant, 
          data: userScore, 
          borderColor: `rgba(${gestion.color.graph[i]}, 1)`, 
          backgroundColor: selectedType !== 'radar' ? `rgba(${gestion.color.graph[i]}, 0.5)` : `rgba(${gestion.color.graph[i]}, 0)`  });
    });
    }
    let adminDataItem = {
      label : 'Score Attendu',
      data : adminData,
      backgroundColor : selectedType !== 'radar' ? `rgba(${gestion.color.middleGrey}, 0)` : `rgba(${gestion.color.middleGrey}, 0)` ,
      borderColor : selectedType !== 'radar' ? `rgba(175,175,175, 1)` : `rgba(175,175,175, 1)`,
    }

    if (selectedType === 'line') {
     adminDataItem['type'] = 'line'
    }
    
    dataForGraph['datasets'].unshift(adminDataItem)
    return dataForGraph
    
    
  } catch (error) {
    console.log(error)
    throw error
  }
  
},

}

export default gestion;