import axios from 'axios';
import Cookies from 'js-cookie';
import gestion from '../fonctions/gestion'
import { setIsPassword, setIsMail, setLoading, setUser, setUserId, setResponses, setData, setUserMail, setUserPassword, setPin, setUserNewPassword, setUserNewPasswordVerif, setAllText, setPreciseText, setAllThemes, setChapters, setQuestions, setParticipants,setRapportData } from './actions';
import store from '../State/store';
import verifications from './verifications';



const axiosFunc = {
// Connection avec Mail + MDP 
    login: (dispatch, navigate) => {
        const state = store.getState(); 
        const { user, languageSelected, languageDefault } = state.gestion;
        if(user.mail && user.password){
            axios.post(`${process.env.REACT_APP_BACK_URL}/admin/auth/login`,
        {email: user.mail,password: user.password,},
        {params: {languageSelected: languageSelected,languageDefault: languageDefault,},
         headers: {Authorization: `Bearer ${Cookies.get('token')}`}})
        .then ((response) => {
            dispatch(setIsPassword(false)); 
            dispatch(setIsMail(false)); 
            dispatch(setUser(response.data.user)); 
            Cookies.set('userToken', response.data.user.session); 
            if(response.data.user.questionnaires.length < 1){
                gestion.createNotification('info', 'Aucun questionnaire ne vous est attribué, merci de contacter votre administrateur', 'Aucun questionnaire', 4000)
            } else {
                gestion.createNotification('success', `Vous êtes maintenant connecté`, `Bienvenue`, 4000); 
                navigate(`${process.env.REACT_APP_FRONT_LINK}/fiche/${response.data.user.id}/${response.data.user.questionnaires[0].idQuestionnaire[0]}`)
            }
            axiosFunc.getTexte(dispatch, 'titles')
        
        })
        .catch((error) => {dispatch(setIsPassword(true)); dispatch(setIsMail(true))})
        } else {
            dispatch(setIsPassword(true)); dispatch(setIsMail(true))
        }  
    },
    

// Récupère le token nécessaire au back pour identifier l'application
    recupToken: (dispatch) => {
        if(!Cookies.get('token')){
            axios.get(`${process.env.REACT_APP_BACK_URL}/auth/get_token`,
                {headers: {
                    appname: process.env.REACT_APP_NAME,
                    apppass: process.env.REACT_APP_PASS,
                },})
                .then ((response) => {
                    Cookies.set('token', response.data.token)
                    dispatch(setLoading(true))
                })
        }
    },

// Demande au back d'envoyer le mail avec code pin de validation à l'adresse indiquée dans le front
    verifMail: async (navigate, dispatch) => {
        try {
            const state = store.getState();
            const { user, languageSelected, languageDefault } = state.gestion;

        const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/auth/reset_password`,
            {email: user.mail},
            {params: {languageSelected: languageSelected,languageDefault: languageDefault}, headers: {Authorization:`Bearer ${Cookies.get('token')}`}})
            
            gestion.createNotification('info', `${response.data.message}`, "Mail envoyé", 5000)
            dispatch(setUserId(response.data.idUser))
            navigate(`${process.env.REACT_APP_FRONT_LINK}/connect/ask_pin`)

        } catch (error) {
            console.log(error)
         }

    },

// Envoie le code PIN au back pour vérifier la correspondance avec le PIN envoyé
    verifPin: async (dispatch, navigate) => {
        try {
            const state = store.getState();
            const { pin, languageSelected, languageDefault, user } = state.gestion;
        await axios.post(`${process.env.REACT_APP_BACK_URL}/admin/auth/validate_pin/${user.id}`,
                {pin : parseInt(pin.join('')) },
                {params: {languageSelected: languageSelected,languageDefault: languageDefault}, headers: {Authorization:`Bearer ${Cookies.get('token')}`}})
                navigate(`${process.env.REACT_APP_FRONT_LINK}/connect/ask_new_password`) 
        } catch (error) {
            gestion.createNotification('error', `${error.response.data.errorMessage}`, 'Mauvais code PIN', 5000 )
            dispatch(setPin([]))
        }
        
    },

// Envoie du nouveau Mot de passe 
    sendNewPassword: (navigate, dispatch) => {
        const state = store.getState();
        const {newPassword, newPasswordVerif, id} = state.gestion.user
        const {languageSelected,languageDefault} = state.gestion

        if(verifications.isPassword(newPassword)) {
            if(newPassword === newPasswordVerif){
            axios.put(
                // URL
                `${process.env.REACT_APP_BACK_URL}/admin/auth/update_password/${id}`,
                // BODY
                {password: newPassword},
                // PARAMS
                {params: {languageSelected: languageSelected,languageDefault: languageDefault}, headers: {Authorization:`Bearer ${Cookies.get('token')}`}}
                )
            .then((reponse) => {
                navigate(`${process.env.REACT_APP_FRONT_LINK}/connect`)
                gestion.createNotification('success','Votre mot de passe a bien été enregistré, vous pouvez vous connecter', 'Modification réussie', 5000)
                dispatch(setIsMail(false))
                dispatch(setIsPassword(false))
                dispatch(setUserMail())
                dispatch(setPin([]))
                dispatch(setUserPassword())
                dispatch(setUserNewPassword())
                dispatch(setUserNewPasswordVerif())
            })
        } else {
            gestion.createNotification('warning', 'Les deux mots de passe ne sont pas identiques', 'Attention', 5000)
        }} else {
            gestion.createNotification('warning', 'Votre mot de passe doit contenir au minimum 8 caractères, 1 chiffre, 1 Majuscule, 1 minuscule et un caractère spécial', 'Attention', 8000)
        }
    },


// Demande des réponses de l'utilisateur
    askAnswersUser: (idUser, idQuestionnaire, idTheme, dispatch) => {
        const state = store.getState()
        axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_pdf_answers_questions/${idQuestionnaire}/${idUser}?languageSelected=${state.gestion.languageSelected}&languageDefault=${state.gestion.languageSelected}`, {
            headers: {Authorization: `Bearer ${Cookies.get('token')}`}})
        .then((response) => {
            if(idTheme){
                const responsesFromBack = response.data.find(obj => obj.id === idTheme)
                dispatch(setResponses({infos: {theme: responsesFromBack.title, user: responsesFromBack.identity.company}, answers: responsesFromBack.questions}))
            } else {

                dispatch(setResponses(response.data))
            }})
        .catch((error) => {console.log(error)})       
    },

// Demande des datas à afficher d'un questionnaire
    getDataForDataTable: (idQuestionnaire, dispatch, navigate) => {
        const state = store.getState()
        axios.get(`${process.env.REACT_APP_BACK_URL}/admin/questionnaire/get_datatable/${idQuestionnaire}`, 
        {
            headers: { Authorization: `Bearer ${Cookies.get('token')}`, Session: Cookies.get('userToken')},
            params: { languageSelected: state.gestion.languageSelected, languageDefault: state.gestion.languageDefault }
        })

        .then((response) => {
            dispatch(setData(response.data.content.data))
        })

        .catch((error) => {
            if(error.status)
            navigate(`${process.env.REACT_APP_FRONT_LINK}/connect`)
        })
    },

// Demande pour récuperer les textes necessaires à la connexion

    getTexte: (dispatch, cle) => {
        const state = store.getState()
        return new Promise ((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_BACK_URL}/preferences/translations/get_text${cle ? '/'+cle : ''}`,
            {
                headers: {Authorization: `Bearer ${Cookies.get('token')}`},
                params: { languageSelected : state.gestion.languageSelected,languageDefault:state.gestion.languageDefault}
            })
            .then((response) => {
                if(cle){
                    dispatch(setPreciseText(response.data[cle], cle));
                } else {
                    dispatch(setAllText(response.data))
                }
                resolve()
            })
            .catch((error) => {
                reject(error)
            })
        })
    },

// Envoie le token en cookies au Back pour vérifier qu'il soit toujours valable, s'il l'est, alors le back renvoie toutes les informations de connexion. 
    verifUserToken: (dispatch, navigate) => {
        const state = store.getState()
                axios.get(
                    `${process.env.REACT_APP_BACK_URL}/admin/auth/verify_user_token`,
                    {headers: { Authorization: `Bearer ${Cookies.get('token')}`, userToken: Cookies.get('userToken')},},
                )
                .then((response) => {
                    dispatch(setUser(response.data.user))
                    axiosFunc.getTexte(dispatch, 'titles')
                })
                .catch((error) => {navigate(`${process.env.REACT_APP_FRONT_LINK}/connect`)})
    },

// Récupère les thèmes et id du questionnaire
    getThemes : (idQuestionnaire, dispatch) => {
        const state = store.getState()
        if(state.gestion.allThemes && !state.gestion.allThemes[0]){
            axios.get(`${process.env.REACT_APP_BACK_URL}/admin/questionnaire/get_themes/${idQuestionnaire}`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}`, Session: Cookies.get('userToken')},
                params: {languageDefault: state.gestion.languageDefault, languageSelected: state.gestion.languageSelected}
            },
            )
            .then((response) => {dispatch(setAllThemes(response.data))})
        }
    },

    getHomepage: (id) => {
        if(id){
            axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_homepage`)
            .then((response) => {console.log(response)})
            .catch((error) => console.log(error))
        }
    },
    
    getChapters: (idTheme, dispatch) => {
        const state = store.getState()
        dispatch(setChapters())
        axios.get(`${process.env.REACT_APP_BACK_URL}/admin/composant/get_chapters_score/${idTheme}`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}`, Session: Cookies.get('userToken')},
                params: {languageDefault: state.gestion.languageDefault, languageSelected: state.gestion.languageSelected} 
            }
        )
        .then((response) => {
            dispatch(setChapters(response.data))
        })
    },

    getAnswers : async (idTheme, idParticipant, dispatch) => {
        const state = store.getState()
       try {
           
           const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/admin/composant/get_answers_chapter/${idTheme}${idParticipant ? '/'+idParticipant : ''}`,
           {
               headers: { Authorization: `Bearer ${Cookies.get('token')}`, Session: Cookies.get('userToken')},
               params: {languageDefault: state.gestion.languageDefault, languageSelected: state.gestion.languageSelected} 
            }
        )
        dispatch(setQuestions(response.data))
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
    },

    getParticipants : (idQuestionnaire, dispatch) => {
        const state = store.getState()
        axios.get(`${process.env.REACT_APP_BACK_URL}/admin/questionnaire/get_participants/${idQuestionnaire}`,
            {
                headers: { Authorization: `Bearer ${Cookies.get('token')}`, Session: Cookies.get('userToken')},
                params: {languageDefault: state.gestion.languageDefault, languageSelected: state.gestion.languageSelected} 
            }
        )
        .then((response) => {
            dispatch(setParticipants(response.data))
        })
    },


};  

export default axiosFunc