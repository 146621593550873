import fond from '../../Images/Fonds/bas_de_page.png'

const initialState = {
    loading: false,
    includeLabelScore: true,
    pin: [],
    languageSelected: 'FR',
    languageDefault: 'EN',
    isMail: false,
    isPassword: false,
    badLogin: false, 
    background: {
      backgroundImage: `url(${fond})`,
      backgroundSize: 'cover',
      backgroundPosition: 'right bottom calc(10% - 25vh)',
      backgroundRepeat: 'no-repeat',
    },
    openMenuRight: false,
    user: null,
    allThemes: [],
    displayModalResponse : false,

    color: {
      darkBlue: 'rgb(0,129,183)',
      blue: 'rgb(0,174,217)',
      middleBlue: 'rgb(77, 219, 255)',
      darkGrey: 'rgb(88,88,90)',
      grey: 'rgb(135,136,138)',
      middleGrey: 'rgb(175,175,175)',
      darkOrange: 'rgb(218,91,21)',
      orange: 'rgb(244,157,0)',
      middleOrange: 'rgb(254, 204, 109)',
      green: 'rgb(16, 187, 44)',
      
      majorColor: 'rgb(218,91,21)',
      primaryToggle: {
        main: 'rgb(218,91,21)',
        primary: 'rgb(218,91,21)',
        light: 'rgba(218,91,21,1)',
        dark: '#1565c0',
        contrastText: '#fff'
      },
      graph: ['218,91,21', '96, 255, 86', '255, 86, 86', '86, 255, 252', '86, 119, 255', '219,86,255'],
    },

    toggle : {},
    dataToExport : [[]],
    dataThemeToExport : [[]],
  }

    
    function gestion(state = initialState, action){
      switch (action.type){
        case 'SET_LOADING': return{...state, loading : action.payload};
        case 'SET_LANGUAGE': return{...state, languageSelected : action.payload};
        case 'SET_IS_MAIL': return{...state, isMail: action.payload};
        case 'SET_IS_PASSWORD': return{...state, isPassword: action.payload};
        case 'SET_USER_MAIL': return{...state, user : {...state.user, mail: action.payload}};
        case 'SET_USER_ID' : return {...state, user : {...state.user, id: action.payload}};
        case 'SET_USER_PASSWORD': return{...state, user : {...state.user, password: action.payload}};
        case 'SET_BAD_LOGIN': return{...state, badLogin: action.payload};
        case 'SET_PIN': return{...state, pin: action.payload}
        case 'SET_USER_NEW_PASSWORD': return{...state, user : {...state.user, newPassword: action.payload}};
        case 'SET_USER_NEW_PASSWORD_VERIF': return{...state, user : {...state.user, newPasswordVerif: action.payload}};
        case 'SET_USER': return{...state, user: action.payload};
        case 'SET_OPEN_MENU_RIGHT': return{...state, openMenuRight: action.payload};
        case 'SET_DISPLAY_MODAL_RESPONSE': return{...state, displayModalResponse: action.payload}
        case `SET_TOGGLE_${action.key}` : return{...state, toggle : {...state.toggle, [action.key.toLowerCase()]: action.payload}}
        case `SET_MENU_HAUT`:return{...state,menuHaut:action.payload}
        case 'SET_ALL_THEMES': return{...state, allThemes: action.payload}
        case 'SET_INCLUDE_LABEL_SCORE': return{...state, includeLabelScore : action.payload}
        case 'SET_DATA_TO_EXPORT': return{...state, dataToExport : action.payload}
        case 'SET_DATA_THEME_TO_EXPORT': return{...state, dataThemeToExport : action.payload}
        default: return state;
    }
    }
    
  



    export default gestion;