import { connect, useDispatch } from "react-redux"
import deco from '../Images/Icones/deconnexion.png'
import gestion from '../Reducer/fonctions/gestion';
import { useNavigate } from "react-router-dom";
import logo from "../Images/Logos/logo_uranie_refresh.png";
import {Link} from "react-router-dom";
import MenuHaut from "../Pages/Menu haut/menu_haut";

const Header = ({user}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <div className='container__header'>
            <div className='container__header__part container__header__part__flex1'> 
                <img src={deco} className='img__white img__size__70 cursor__pointer ' onClick={() => {gestion.disconnect(dispatch, navigate)}} alt="deconnexion" />
                <div className='container__header__part__text'>
                    <p className='reset text__size__1-25'>{user && user.firstname ? user.firstname : null}</p> 
                    <p className='reset text__size__1-25'>{user && user.lastname ? user.lastname : null}</p>
                </div>
            </div>

            <div className="container__header__part container__header__part__flex7">

                <MenuHaut />
            </div>
            <Link to='https://uranie-conseil.fr/' target="blank" rel="noopener noreferrer" className="container__header__part container__header__part__flex1 ">
                <img src={logo}  alt="logo"className="img__size__70"/>
            </Link>

        </div>
    )
}

const states = (state) => ({
    user: state.gestion.user,
})

export default connect(states)(Header)