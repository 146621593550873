import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.css';
import check from '../../Images/Icones/check.png';
import error from '../../Images/Icones/error.png';
import loupe from '../../Images/Icones/loupe.png';
import gestion from '../../Reducer/fonctions/gestion';
import { FilterMatchMode } from 'primereact/api';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dropdown } from 'primereact/dropdown';


const Rapport = ({ dataTable, idQuestionnaire, themes, participantsSelected }) => {
    const [data, setData] = useState([])
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        themes.forEach((t) => {
        if(!statuses.includes(t.name)){
            setStatuses(prevStatuses => [...prevStatuses, t.name]);
        }})
    }, [])    

    const [filters] = useState({ 
        participant: { value: null, matchMode : FilterMatchMode.CONTAINS}, 
        label : { value: null, matchMode: FilterMatchMode.EQUALS },
        finish : { value : null, matchMode: FilterMatchMode.EQUALS },
        themes: {value: null, matchMode: FilterMatchMode.CONTAINS}
    })
    
    useEffect(() => {
        let actualData = []
        setData([])
        if(participantsSelected && participantsSelected[0]){
            actualData = participantsSelected
        } else {
            actualData = dataTable.data
        }
        actualData.map(user => {
            let tabThemes = []
            user.scoreTheme.map(score => {
                const themeFind = themes.find(obj => obj.id === score.idTheme)
                tabThemes.push(themeFind.name)
            })
            let concatThemes = ''
            tabThemes.forEach(t => {
                concatThemes += t + ' '
            })
            let difference = '';
            if (user.totalUserScore < user.totalAdminScore) {
                difference = parseInt(`-${user.totalAdminScore - user.totalUserScore}`);
            } else {
                difference = parseInt(`+${user.totalUserScore - user.totalAdminScore}`);
            }
            
            let newData = {}
            newData['id'] = user.id
            newData['participant'] = user.participant
            newData['themes'] = concatThemes
            newData['totalUserScore'] = user.totalUserScore
            newData['userCreation'] = user.userCreation
            newData['userModif'] = user.userModif
            newData['userValidate'] = user.userValidate
            newData['label'] = user.totalUserScore > user.totalAdminScore ? true : false
            newData['difference'] = difference
            newData['finish'] = user.finish
            
            setData((prevData) => [...prevData, newData])
            
        });

    }, [dataTable.data, participantsSelected])


    const verifiedRowFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };

    const themesRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Choisissez-en un" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };

    

    return (
        dataTable && dataTable.data && dataTable.data[0] ?
            <div className='page__datatable'>
                <DataTable value={data} removableSort scrollable filters={filters} filterDisplay="row" scrollHeight="80vh" className='rapport__datatable' >
                    <Column field="userCreation" header="Dernière MàJ" filterField="userModif" sortable />
                    <Column field="participant" header="Participant" filterField="participant" sortable filter filterPlaceholder='Chercher par nom' />
                    <Column field="themes" header="Thèmes" showFilterMenu={false} filter sortable filterElement={themesRowFilterTemplate} />
                    <Column className="rapport__text__center" field="totalUserScore" header="Score" sortable />
                    {/* <Column field="totalUserScoreVersion" header="Version -1" sortable /> */}
                    <Column className="rapport__text__center" field="difference" header="Différence" sortable />
                    <Column className="rapport__text__center" field="label" filterElement={verifiedRowFilterTemplate} showFilterMenu={false} filter header="Label" sortable body={(rowData) => rowData.label
                        ? <img src={check} alt="Labelisé" className='ico' />
                        : <img src={error} alt="Non Labelisé" className='ico' />} />
                    <Column className="rapport__text__center" field="finish" showFilterMenu={false} filter filterElement={verifiedRowFilterTemplate} header="Etat" sortable body={gestion.addCircleState} />
                    <Column className="rapport__text__center" field="userValidate" header="Validation" sortable />
                    <Column align='center' field="details" header="Rapport" sortable={false} body={(rowData) => <a href={`https://labelexco.com/rapport/1/${rowData.id}`} target='u_blanck'><img src={loupe} alt="Détails" className='ico rapport__text__center' /></a>} />
                </DataTable>

            </div> : null

    )
};

const states = (state) => ({
    dataTable: state.dataTable,
    themes: state.data.themes,
    participantsSelected : state.dataTable.participantsSelected
});

export default connect(states)(Rapport);
