import { connect } from "react-redux"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.css';
import gestion from "../../Reducer/fonctions/gestion"
import { useDispatch } from "react-redux";
import loupe from '../../Images/Icones/loupe.png'
import { useState } from "react"
import { FilterMatchMode } from "primereact/api"
import { setParticipantsSelected } from "../../Reducer/fonctions/actions";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
const ClassicList = ({dataTable}) => {

    const dispatch = useDispatch()
    const [filters] = useState({ 
        participant: { value: null, matchMode : FilterMatchMode.STARTS_WITH },
        finish : { value : null, matchMode: FilterMatchMode.EQUALS },
    })

    const verifiedRowFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
    };
  
    return (
        dataTable && dataTable.data && dataTable.data[0] ?
        <div className="cabinet__datatable">
            <DataTable 
                value={dataTable.data} 
                selection={dataTable.participantsSelected} 
                removableSort
                onSelectionChange={(e) => dispatch(setParticipantsSelected(e.value))}
                filters={filters} 
                filterDisplay="row" 
            >
            <Column selectionMode="multiple"></Column>
            {dataTable.classicList.map((col, i) => {
                return(
                    col.sortable ? 
                        col.field === 'finish' ? 
                            <Column key={col.field} showFilterMenu={false} filter filterElement={verifiedRowFilterTemplate}  field={col.field} header={col.header}  dataType="boolean" body={gestion.addCircleState} sortable />
                            : col.field === 'participant' ?
                            <Column key={col.field} field={col.field} header={col.header} filterField="participant" sortable filter filterPlaceholder='Chercher par nom' />
                        :   <Column key={col.field} sortable field={col.field} header={col.header}  />
                    :  col.field === 'details' ? 
                        <Column key={col.field} field={col.field} header={col.header} body={((rowData) => gestion.addIco(rowData, loupe))} />
                    :   <Column key={col.field} field={col.field} header={col.header} />
                )
            })}
            </DataTable>
        </div>
        : null
    )
}

const states = (state) => ({
    dataTable : state.dataTable,

})

const actions = {

}

export default connect(states, actions)(ClassicList)