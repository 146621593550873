import { connect, useDispatch } from "react-redux"
import Cabinets from "../MenuDroit/cabinets"
import Header from "../../Elements/header"
import Fiche from "../Principal/Fiche"
import { useEffect } from "react"
import axiosFunc from "../../Reducer/fonctions/axios"
import { useNavigate, useParams } from "react-router-dom"
import { Triangle } from 'react-loader-spinner'
import ConstructionPage from "../../Elements/constructionPage"
import Graphique from "../Graphique/graphique"
import Reponses from "../Réponses/reponses"
import Rapport from "../Rapport/rapport"


const Accueil = ( {openMenuRight, data, majorColor, menuTable}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { idQuestionnaire, menu } = useParams()

    useEffect(() => {
       // axiosFunc.verifUserToken(dispatch, navigate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[menu])

    useEffect(() => {
        if(idQuestionnaire){
            axiosFunc.getDataForDataTable(idQuestionnaire, dispatch, navigate);
            axiosFunc.getThemes(idQuestionnaire, dispatch)
        }
          // eslint-disable-next-line
      }, [idQuestionnaire]) 

    return (
        <div className='page'>
            <Header />
            {data && data[0] ? 
            <div className={openMenuRight ? 'page__principale grid__1' : 'page__principale grid__71'}>
                <Cabinets />
                {menu === menuTable[0].lien ? <Fiche /> 
                : menu === menuTable[1].lien ? <Graphique /> 
                : menu === menuTable[2].lien ? <Reponses />
                : menu === menuTable[3].lien ? <Rapport />
                : <ConstructionPage />}
            </div>        
        :
        <Triangle
            color={majorColor}
            height="25vh"
            width="25vw"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass="loader-triangle"
        />
        
        }
        </div>
    )
}

const states = (state) => ({
    openMenuRight: state.gestion.openMenuRight,
    data: state.dataTable.data,
    majorColor: state.gestion.color.majorColor,
    menuTable: state.textes.titles.menu
})

export default connect(states)(Accueil)