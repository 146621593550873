import { connect, useDispatch } from "react-redux"
import { setOpenMenuRight,setIncludeLabelScore } from "../../Reducer/fonctions/actions"
import ClassicList from './ClassicList'
import { useEffect, useState } from "react"
import { Checkbox } from 'primereact/checkbox';

const Cabinets = ( {titles, openMenuRight, gestion} ) => {
    const dispatch = useDispatch()
    const [delayedOpen, setDelayedOpen] = useState(openMenuRight)

    useEffect(() => {
        let timer;
        if (!openMenuRight) {
            timer = setTimeout(() => {
                setDelayedOpen(false);
            }, 250);
        } else {
            setDelayedOpen(true);
        }

        return () => clearTimeout(timer);
    }, [openMenuRight]);
    
    return (
        <div className={ !openMenuRight ? 'container__cabinets' : 'container__cabinets container__cabinets__close'}>
            <div className={openMenuRight ? ' cursor__pointer formes__arrow formes__position formes__position__participants formes__taille__participants formes__couleur__majorColor reverse' : 'formes__arrow formes__position formes__position__participants formes__taille__participants formes__couleur__majorColor cursor__pointer '} onClick={() => {dispatch(setOpenMenuRight(!openMenuRight))}}></div>
            {delayedOpen ? null :
                <>
                <div className='cabinet__option'>Inclure les questions hors label ? <Checkbox checked={gestion.includeLabelScore} onChange={(e) => dispatch(setIncludeLabelScore(e.checked))}/></div>
                <div className='separator'></div>
                <h1 className='title__cabinets cursor__pointer' onClick={() => {dispatch(setOpenMenuRight(!openMenuRight))}}>{titles.participants}</h1>
                <div className='separator'></div>
                 
                 <ClassicList />
                 </>
}     
        </div>
    )
}

const states = (state) => ({
    titles: state.textes.titles,
    openMenuRight: state.gestion.openMenuRight,
    gestion: state.gestion,
})

export default connect(states)(Cabinets)