import { Route, Routes, useNavigate } from "react-router-dom";
import { isBrowser } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';
import routes from "../Routes";
import { useEffect, useState } from "react";
import axiosFunc from "../Reducer/fonctions/axios";
import gestion from '../Reducer/fonctions/gestion';
import ModalResponses from "../Elements/Modals/allResponses";
import Cookies from 'js-cookie';
import { Triangle } from 'react-loader-spinner'

import { NotificationContainer } from "react-notifications";

const App = ( {background, majorColor} ) => {
  const [isLoad, setIsLoad] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  useEffect(() => {
    // axiosFunc.verifUserToken(dispatch, navigate)
    gestion.importCSS(isBrowser)
    axiosFunc.recupToken(dispatch)
    if(Cookies.get('token')){
      const fetchData = async () => {
        await axiosFunc.getTexte(dispatch)
      };
      fetchData().then(() => {
        setIsLoad(true)
      }).catch(error => {
        gestion.createNotification('error', error.message, error.name + ' : ' + error.code)
      })
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [Cookies.get('token')])

  useEffect(() => {
    axiosFunc.verifUserToken(dispatch, navigate)
  }, [])

  return (
    isLoad ? 
    <div className="App" style={background}>
      <NotificationContainer />
      <Routes>
          {routes.web.map((route, index) => (
              <Route key={index}
              path={route.path}
              exact
              element={<route.component />}
              />
          ))}
      </Routes>
      <ModalResponses />
    </div>

    :
    <div className="App" style={background}>
    <Triangle
            color={majorColor}
            height="25vh"
            width="25vw"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass="loader-triangle"
        />
      </div>
  );
}

const states = (state) => ({
  background: state.gestion.background,
  data: state.data,
  gestionState: state.gestion,
  textes: state.textes,
  majorColor: state.gestion.color.majorColor,
   });



export default connect(states)(App); 
