import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import axiosFunc from "../../Reducer/fonctions/axios"
import { useParams } from "react-router-dom"
import { CSVLink } from "react-csv"
import { Triangle } from 'react-loader-spinner'
import ExportExcel from "../../Elements/exportExcel"

const Reponses = ({participantsSelected, themes, chapters, participants, questions, majorColor, includeLabel}) => {
    const { idQuestionnaire} = useParams()
    const [selectedTheme, setSelectedTheme] = useState(null)
    const [selectedChapter,setSelectedChapter] = useState('all')
    const [headers, setHeaders] = useState([])
    const dispatch = useDispatch()
    const [CSVExportData, setCSVExportData] = useState([])
    const [displayResponses, setDisplayResponses] = useState([])

    const themeName = selectedTheme &&  themes ? themes.find(obj => obj.id === parseInt(selectedTheme)).name : null
    
    useEffect(() => {
     if(selectedTheme){
         axiosFunc.getAnswers(selectedTheme, null, dispatch)
         axiosFunc.getParticipants(idQuestionnaire, dispatch)
    } 
    }, [selectedTheme])

    useEffect(() => {
        if(selectedTheme && selectedTheme[0] && headers && headers[0] && questions && questions[0]){
            ExportData('csv')
        }
    }, [selectedTheme, selectedChapter, headers, questions])

    const ExportData = () => {
        let selectedQuestions = []
        if(selectedChapter && selectedChapter !== 'all') {
            const findChapter = questions.find(obj => obj.id === parseInt(selectedChapter))
            selectedQuestions = [findChapter]
        } else {
        selectedQuestions = questions
        }
        let questionsAnswers = []
        selectedQuestions.forEach((s, sIndex) => {
            s.questions.forEach((q, qIndex) => {
                q.answers.forEach((a, aIndex) => {
                    const findUserName = headers.find(obj => obj.id === a.id)
                    if(findUserName){
                        questionsAnswers.push({"Question" : q.text, "Participant" : findUserName.participant, "Réponse" : a.response})
                    }
                })
            })
        })
        setCSVExportData(questionsAnswers)
    }

    useEffect(() => {
        setCSVExportData([])
        if(questions && questions[0]){
            ExportData()
        }
    }, [participants, participantsSelected, chapters, themes])

    useEffect(() => {
        setHeaders(null)
        let data = []
        if(participantsSelected && participantsSelected[0]) {
            participantsSelected.forEach((p, pIndex) => {
                data.push({id: p.id, participant : p.participant, score: includeLabel ? p.totalUserScore : p.totalLabelUserScore, scoreTheme : p.scoreTheme })
            });
        } else if (participants && participants[0]){
            participants.forEach((p, pIndex) => {
                data.push({id: p.id, participant : p.participant, score: includeLabel ? p.totalUserScore : p.totalLabelUserScore, scoreTheme : p.scoreTheme })
            });
        }
        setHeaders(data) 
    }, [participants, participantsSelected, includeLabel])
    
    useEffect(() => {
        if(selectedTheme){
            axiosFunc.getChapters(selectedTheme, dispatch)
        }
    }, [selectedTheme, themes]) 

    const changeDisplay = (id) => {
        setDisplayResponses((prevResponses) => {
            if (prevResponses.includes(id)) {
              // Remove the id if it exists
              return prevResponses.filter(responseId => responseId !== id);
            } else {
              // Add the id if it does not exist
              return [...prevResponses, id];
            }
          });
    }



    return (
        
        <div className="reponses">
            <div className='reponses__legende'>
            <select onChange={(e) => {setSelectedChapter('all'); setSelectedTheme(e.target.value)}} defaultValue={'choose'} className='graphique__select'>
                <option className="graphique__option" disabled value={"choose"}>Choisissez un thème</option>
                {themes.map((theme, index) => (
                    <option key={index} className="graphique__option" value={theme.id}>{theme.name}</option>
                )
                )}

            </select>
            <select onChange={(e) => {setSelectedChapter('all'); setSelectedChapter(e.target.value); if(e.target.value !== 'all') changeDisplay(parseInt(e.target.value))}} className='graphique__select'>
            <option className="graphique__option" value={'all'}>Afficher tous les chapitres</option>
                {chapters && chapters[0] && chapters.map((c, index) => {
                if(includeLabel){
                    return (
                        <option key={index} className="graphique__option" value={c.id}>{c.name}</option>
                    )
                } else {
                    if(c.scoreAdmin > 0){
                        return (
                            <option key={index} className="graphique__option" value={c.id}>{c.name}</option>
                        )
                    }
                }
            }
                )}
            </select>
            </div>
            {CSVExportData && CSVExportData[0] ? 
            <div className='reponses__toggle__container'>
                <CSVLink data={CSVExportData} filename={themeName ? "AutoVision - CSV Réponses " + themeName : 'Theme non trouvé'} className='graphique__toggle'>CSV</CSVLink>
                <ExportExcel excelData={CSVExportData} fileName={"AutoVision - Export Excel"} sheetName={selectedTheme && selectedTheme !== 'all' ? selectedTheme : 'Global'} />
            </div>
            : null }
            {selectedTheme ?
            chapters && chapters[0] && themes && headers ? 
            <div className='reponses__table__container'>
                <table className='reponses__table'>
                    <thead>
                        <tr className='reponses__table__fixe reponses__table__fixe__top'>
                            <th className='reponses__table__zindex reponses__table__fixe reponses__table__fixe__topleft'>
                                
                            </th>
                            {headers && headers[0] && headers.map((p, pIndex) => {
                                return (
                                    <th className='reponses__table__user reponses__table__zindex'>
                                        <div>
                                            {p.participant} 
                                        </div>
                                    </th>
                                )
                            })
                        }
                        </tr>
                    </thead>
                    <tbody className='reponses__table__body'>
                        {chapters.map((c, cIndex) => {
                            let display = true
                            if(parseInt(selectedChapter) !== c.id && selectedChapter !== 'all'){
                                display = false
                            } else if (!includeLabel) {
                                if(c.scoreAdmin === 0) {
                                    display = false
                                }
                            }
                            if(display){
                            return (
                            <>
                                <tr className='reponses__table__height'>
                                    <td className='reponses__table__title reponses__table__title__chapter reponses__table__fixe reponses__table__fixe__left cursor__pointer' onClick={() => changeDisplay(c.id)}>
                                        <div>
                                            <i  className={displayResponses.includes(c.id) ? "pi pi-angle-up" : "pi pi-angle-down"}></i> {c.name}
                                        </div>
                                        <div className='reponses__table__title__chapter__score'>
                                            {c.scoreAdmin}
                                        </div>
                                        </td>
                                    {headers && headers[0] && headers.map((p, pIndex) => 
                                    {
                                        const themeObj = p.scoreTheme && p.scoreTheme.find(obj => obj.idTheme === parseInt(selectedTheme))
                                        const userObj = themeObj && themeObj.chapterScore && themeObj.chapterScore.find(obj => obj.id == c.id)
                                        return (
                                         <td className='reponses__table__title__score'>{userObj && userObj.score ? userObj.score : 0}</td>
                                    )})}
                                </tr>
                                {c.questions.map((q, qIndex) => (
                                displayResponses.includes(c.id)) ?
                                    <tr className='reponses__table__height'>
                                        <td className='reponses__table__height reponses__table__fixe reponses__table__fixe__left reponses__table__questions'>{q.text}</td>
                                        {questions && questions[cIndex] && questions[cIndex].questions[qIndex] && headers && headers[0] && headers.map((th, thIndex) => {
                                            const findAnswer = (questions[cIndex].questions[qIndex].answers.find(obj => obj.id === th.id))
                                            if(findAnswer && findAnswer.response) {
                                                return (
                                                    <td className='reponses__table__height reponses__table__user'>{findAnswer.response}</td>
                                                )
                                            } else {
                                                return ( <td className='reponses__table__height reponses__table__user'></td> )
                                            }
                                        })}
                                    </tr> : null )
                                }

                            </>
                        ) }
                    })}
                        <tr>
                            <td className='reponses__table__total reponses__table__fixe__left reponses__table__zindex'>
                                <div className='reponses__table__total__attendu'>
                                    <div>
                                        Score attendu :  
                                    </div>
                                    <div className='reponses__table__score'>
                                        {themes.find(obj => obj.id === parseInt(selectedTheme)).scoreAdmin}
                                    </div> 
                                </div>
                                
                            </td>
                            {headers && headers[0] && headers.map((p, pIndex) => {
                                const themeObj = p.scoreTheme && p.scoreTheme.find(obj => obj.idTheme === parseInt(selectedTheme))
                                return (
                                    <td className={includeLabel ? 'reponses__table__user__score reponses__table__user__score__label reponses__table__total' : 'reponses__table__user__score reponses__table__total'}>
                                        {themeObj ? includeLabel ? themeObj.userScore : themeObj.labelUserScore : null}
                                    </td>
                                )
                            }
                        )}
                        </tr>
                    </tbody>
                </table>
            </div>
                : <Triangle
                color={majorColor}
                height="25vh"
                width="25vw"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass="loader-triangle"
            />
                : 'Choisissez un thème' }
        </div>
    )

}

const mapState=(state)=> ({
    themes: state.gestion.allThemes,
    participantsSelected : state.dataTable.participantsSelected,
    chapters: state.data.chapters,
    participants: state.dataTable.data,
    questions: state.data.questions,
    allData: state.data,
    majorColor: state.gestion.color.majorColor,
    includeLabel : state.gestion.includeLabelScore,
})

export default connect(mapState)(Reponses)