import enConstruction from '../Images/Fonds/en_construction.png'

const ConstructionPage = () => {
    return (
        <div className='construction'>

            <img src={enConstruction} alt='En construction' className='img__construction' />
            <div className='construction__title'>
                En cours de construction
            </div>
            <div className='construction__text'>
                Cette page est en cours de réalisation, nous faisons au mieux pour vous offrir la meilleure expérience avec notre outil. Merci de votre patience. 
            </div>
        </div>
    )
}

export default ConstructionPage