import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Input, Bouton } from "../../Elements"
import { setUserMail, setUserPassword } from "../../Reducer/fonctions/actions"
import axiosFunc from '../../Reducer/fonctions/axios'
import { useDispatch } from "react-redux"

const Login = ({ connexion, input, isMail, isPassword, homepage, buttonTexte}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()


    return (
        <div>
            <p className="text__center text__size__2">{homepage.login}</p>
            <form className='container__flex container__flex__column container__flex__center'>
                <Input 
                    placeholder={input.inputMail} 
                    action={(event) => { dispatch(setUserMail(event.target.value)) }} 
                    alertInput={isMail} 
                    id={'input__mail'} 
                    autoComplete={'email'} 
                />
                <Input 
                    placeholder={input.inputMdp} 
                    action={(event) => { dispatch(setUserPassword(event.target.value)) }} 
                    alertInput={isPassword} 
                    secureInput={true} 
                    id={'input__password'} 
                    autoComplete={'current-password'}
                />
            </form>
                <div className='firstConnection'>
                    <div>{homepage.firstConnection} <Link to={process.env.REACT_APP_FRONT_LINK+'/connect/ask_mail'} className='firstConnection__link' >{connexion.link.nom}</Link></div>
                
                </div>
                {isMail || isPassword ? <p className='text__alert'>{connexion.messageErreur} <br /> {connexion.messageMDP} <span onClick={() => {navigate(`${process.env.REACT_APP_FRONT_LINK}/connect/${connexion.link.url}`)}} className='link link__connexion'>{connexion.link.nom}</span></p> : null}
            <div className='container__flex container__flex__column container__flex__center background__white'>
                <Link to={process.env.REACT_APP_FRONT_LINK+'/connect/ask_mail'} className='link'><p className='card card__message card__background'>{connexion.firstConnexion}</p></Link>
                <Bouton text={buttonTexte.login} action={() => {axiosFunc.login(dispatch, navigate)}} />
            </div>
        </div>
    );
}

const states = (state) => ({
    connexion: state.textes.connexion,
    input: state.textes.input,
    isMail: state.gestion.isMail,
    isPassword: state.gestion.isPassword,
    user: state.gestion.user,
    homepage: state.textes.app.homePage,
    buttonTexte : state.textes.app.button
})

export default connect(states)(Login);
